<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Atividades</li>
    </ol>
  </nav>
  <div class="table-responsive">
		<div class="form-group">
			<div class="row">
				<div class="form-group col-md-3">
					<input type="date" class="form-control" v-model="activityDate" >
				</div>
				<div class="col-md-7 col-xs-7">
					<input v-model="param" placeholder="Digite para pesquisar" class="form-control" @keypress="callSearch">
				</div>
				<div class="col-md-2 col-xs-2">
					<a type="button" @click="search()" class="btn btn-dark">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
							<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
						</svg>
					</a>
				</div>
			</div>
		</div>

    <div class="card mb-1" v-for="record in records" :key="record.id">
      <div class="row card-body">
        <div class="col-10">
          <h5 class="card-title">{{ record.name }}</h5>
          <p class="card-text">{{ record.modality ? record.modality.name : '' }} - {{ record.location ? record.location.name : '' }} - Participantes ativos: {{ record.activeassociates }}</p>
        </div>
        <div class="col-2">
          <router-link class="btn btn-dark" :to="{ name: 'activityPresenceList', params: { id: record.id }}" >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { sAlert } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';
import moment from 'moment';

export default {
  data() {
    return {
			activityDate: moment().format('YYYY-MM-DD'),
			records: [],
			strFields: '',
			page: 1,
			size: 10,
			pages: 0,
			sort: '',
			param: '',
		};
  },
  watch: {
    activityDate() {
      this.fetchGetData();
    }
  },
  computed: {
		filters() {
			if (this.param) return { name: this.param };

			return {};
		},
	},
  methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
		async fetchGetData() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`activities/daylist`, {
				activitydate: this.activityDate,
				params: {
					page: 0,
					sort: this.sort,
					size: 100,
					filter: this.filters,
				}
			})
			.then((res) => {
				this.records = res.data.activities;
				this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		search() {
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
	},
	created () {
		this.fetchGetData();
	}
}
</script>
